import Subscribe from "./subscribe";

export default function Contact() {
  return (
    <>
      <div className="flex justify-center items-center">
        <div className="justify-center items-center">{<Subscribe />}</div>
      </div>
    </>
  );
}
